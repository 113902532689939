import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Translation } from 'react-i18next'
import { Box, Flex, Heading, Text } from 'rebass/styled-components'
import slugify from 'slugify'

import Layout from '../components/Layout'
import Container from '../components/Container'
import { withI18next, Link } from 'gatsby-plugin-i18n'
import { getFirstPresentationVersion } from '../utils/getFirstPresentationVersion'

const doAllPresentationsHaveVersions = (presentations) => {
  return presentations.some(({ node }) => node.versions)
}

const PresentationsPage = ({ data, pageContext }) => {
  const { page, translations } = data
  const { edges: presentations } = data.presentations
  const { language } = pageContext
  const allPresentationsHaveVersions = doAllPresentationsHaveVersions(
    presentations,
  )

  return (
    <Translation>
      {(t) => (
        <Layout language={language} translations={translations}>
          <main>
            {presentations &&
              presentations.length > 0 &&
              allPresentationsHaveVersions && (
                <Box
                  sx={{
                    maxWidth:
                      (page &&
                        page.settings &&
                        page.settings.carousel &&
                        page.settings.carousel.width) ||
                      'none',
                    mx: 'auto',
                    py: 4,
                  }}
                >
                  <Container maxWidth={['100%']} px={0}>
                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 1fr 1fr'],
                        gap: 4,
                      }}
                    >
                      {presentations &&
                        presentations
                          .filter(
                            ({ node: presentation }) =>
                              presentation.language === language ||
                              presentation.language === null,
                          )
                          .map(({ node: presentation }) => {
                            if (!presentation) {
                              return null
                            }

                            const { id, title, slug, versions } = presentation
                            const firstVersion = getFirstPresentationVersion(
                              versions,
                            )
                            if (!firstVersion) {
                              return null
                            }
                            const presentationSlug =
                              slug || slugify(title, { lower: true })
                            const image =
                              presentation &&
                              presentation.image &&
                              presentation.image.local &&
                              presentation.image.local.childImageSharp &&
                              presentation.image.local.childImageSharp.fluid
                            let link = `/presentation/${presentationSlug}/${firstVersion.slug}`

                            return (
                              <Flex
                                key={id}
                                sx={{
                                  flexDirection: 'column',
                                  bg: 'white',
                                  boxShadow: 'card',
                                }}
                              >
                                <Link
                                  to={link}
                                  style={{
                                    display: 'block',
                                    textDecoration: 'none',
                                  }}
                                >
                                  {image && (
                                    <Img
                                      fluid={image}
                                      alt={title}
                                      style={{
                                        maxWidth: '1920px',
                                        maxHeight: '624px',
                                      }}
                                    />
                                  )}

                                  <Container>
                                    <Flex
                                      sx={{ flexDirection: 'column', py: 4 }}
                                    >
                                      <Heading
                                        as="h2"
                                        textTransform="uppercase"
                                        mb={2}
                                      >
                                        {title}
                                      </Heading>

                                      <Text
                                        as="p"
                                        sx={{ textDecoration: 'underline' }}
                                      >
                                        {t('presentations:continue-reading', {
                                          defaultValue: 'Continue reading',
                                        })}
                                      </Text>
                                    </Flex>
                                  </Container>
                                </Link>
                              </Flex>
                            )
                          })}
                    </Box>
                  </Container>
                </Box>
              )}
          </main>
        </Layout>
      )}
    </Translation>
  )
}

PresentationsPage.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}

export default withI18next()(PresentationsPage)

export const pageQuery = graphql`
  query PresentationsPageTemplate(
    $language: String!
    $translatedIds: [String]
  ) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["messages", "presentations", "log-in", "purchase"] }
      }
    ) {
      ...LocalLocaleFragment
    }
    translations: allPages(filter: { _id: { in: $translatedIds } }) {
      edges {
        node {
          id
          title
          slug
          language
        }
      }
    }
    page: pages(
      template: { eq: "presentations" }
      language: { eq: $language }
    ) {
      title
      content
      settings {
        carousel {
          width
        }
      }
    }
    presentations: allPresentations(
      filter: { language: { in: [$language, null] } }
    ) {
      edges {
        node {
          id
          title
          slug
          language
          image {
            local {
              childImageSharp {
                fluid(maxWidth: 1280, quality: 80) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          versions {
            slug
            position
          }
        }
      }
    }
  }
`
