export const getFirstPresentationVersion = (versions) => {
  if (versions && Array.isArray(versions)) {
    const versionsWithPosition0 = versions.filter(
      (version) => version.position === 0 || version.position === null,
    )

    if (versionsWithPosition0 && versionsWithPosition0.length > 0) {
      return versionsWithPosition0[0]
    }
  }
}
